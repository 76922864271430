import deepEqual from "deep-eql";

/**
 * Removes properties from the `newObject` that are either `undefined` or have
 * values that deeply equal the corresponding properties in `oldObject`.
 *
 * @param {T} newObject - The "new" or "current" version of the object.
 * @param {T | undefined} oldObject - The "old" or "previous" version of the object. If undefined, only properties with `undefined` values are removed from `newObject`.
 * @returns {Partial<T>} The modified `newObject` with removed properties.
 * @example
 * const newObj = { a: 1, b: 2, c: undefined };
 * const oldObj = { a: 1, b: 3 };
 * removeUnchanged(newObj, oldObj);
 * // Returns: { b: 2 }
 * @example
 * const newObj = { a: 1, b: undefined };
 * removeUnchanged(newObj);
 * // Returns: { a: 1 }
 * @note The function modifies `newObject` directly.
 */
export function removeUnchanged<T extends object>(
  newObject: T,
  oldObject?: T,
): Partial<T> {
  let template = newObject;

  const keys = Object.keys(template) as (keyof T)[];
  if (oldObject) {
    for (const key of keys) {
      if (template[key] === undefined) delete template[key];
      else if (deepEqual(template[key], oldObject[key])) delete template[key];
    }
  } else {
    for (const key of keys) {
      if (template[key] === undefined) delete template[key];
    }
  }

  return template;
}
